<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Добавить тег</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="addTagForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model.trim="tag.title" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Ключ</label>
                <md-input v-model.trim="tag.key" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Порядок</label>
                <md-input
                  v-model="tag.order"
                  type="number"
                  min="0"
                  @focus="reset"
                  @change="validateOrder"
                />
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="addNewTag">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

const initTag = () => ({
  title: null,
  key: null,
  order: null,
});

export default {
  data() {
    return {
      tag: initTag(),
    };
  },

  methods: {
    ...mapActions("tags", ["createTag"]),

    async addNewTag() {
      const isValid = await this.$refs.addTagForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.createTag(this.tag);
      if (success) {
        this.tag = initTag();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    validateOrder(event) {
      if (event.target.value < 0) {
        this.tag.order = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
